<template>
  <div class="wrapper">
    <div id="container"></div>
    <div class="staff-list">
      <div class="list-content">
        <div class="list-tree">
          <div class="tree-title"><i class="el-icon-share"></i> 人员选择</div>
          <!-- 一级公司 -->
          <div class="company" v-for="(itemCompany, indexCompany) in treeData" :key="indexCompany">
            <!-- 一级公司名称 -->
            <div class="company-name" @click="companyToggle">
              <span class="arrow iconfont icon-shangjiantou" :class="isCompany?'arrow':'unarrow'"></span>
              <span class="el-icon-folder-opened"></span>
              {{ itemCompany.name }}
            </div>
            <!-- 一级公司下所有人员 -->
            <div v-show="isCompany">
              <div class="company-all-staff" @click="staffAllPosition(itemCompany.staffs)">
                <span class="iconfont icon-renyuan1"></span>
                全部人员
              </div>
              <div v-for="(onlineCStaffs, onlineIndexCStaffs) in itemCompany.staffs" :key="onlineIndexCStaffs+'0'">
                <div v-if="onlineCStaffs.online==1">
                  <div class="company-staff" @click="staffPosition(onlineCStaffs)">
                    <span class="iconfont icon-renyuan" :style="onlineCStaffs.online==1? 'color: #00b894;':''"></span>
                    {{ onlineCStaffs.name }}
                  </div>
                </div>
              </div>
              <div v-for="(offlineCStaffs, offlineIndexCStaffs) in itemCompany.staffs" :key="offlineIndexCStaffs+'1'">
                <div v-if="offlineCStaffs.online==0">
                  <div class="company-staff" @click="staffPosition(offlineCStaffs)">
                    <span class="iconfont icon-renyuan" :style="offlineCStaffs.online==0? 'color: #d63031;':''"></span>
                    {{ offlineCStaffs.name }}
                  </div>
                </div>
              </div>
              <!-- 二级部门 -->
              <div class="department" v-for="(itemDepartment, indexDepartment) in itemCompany.childTree" :key="indexDepartment+'2'">
                <!-- 二级部门名称 -->
                <div class="department-name" @click="departmentToggle(itemCompany.childTree, itemDepartment)">
                  <span class="arrow iconfont icon-shangjiantou" :class="itemDepartment.isDepartment?'arrow':'unarrow'"></span>
                  <span class="el-icon-folder-opened"></span>
                  {{ itemDepartment.name }}
                </div>
                <div v-show="itemDepartment.isDepartment">
                  <!-- 二级部门下所有人员 -->
                  <div class="department-all-staff" @click="staffAllPosition(itemDepartment.staffs)">
                    <span class="iconfont icon-renyuan1"></span>
                    全部人员
                  </div>
                  <div v-for="(onlineDStaffs, onlineIndexDStaffs) in itemDepartment.staffs" :key="onlineIndexDStaffs+'3'">
                    <div v-if="onlineDStaffs.online==1">
                      <div class="department-staff" @click="staffPosition(onlineDStaffs)">
                        <span class="iconfont icon-renyuan" :style="onlineDStaffs.online==1? 'color: #00b894;':''"></span>
                        {{ onlineDStaffs.name }}
                      </div>
                    </div>
                  </div>
                  <div v-for="(offlineDStaffs, offlineIndexDStaffs) in itemDepartment.staffs" :key="offlineIndexDStaffs+'4'">
                    <div v-if="offlineDStaffs.online==0">
                      <div class="department-staff" @click="staffPosition(offlineDStaffs)">
                        <span class="iconfont icon-renyuan" :style="offlineDStaffs.online==0? 'color: #d63031;':''"></span>
                        {{ offlineDStaffs.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 人员在线列表 -->
        <div class="status-list">
          <div class="status-item" v-for="(item, index) in tableData" :key="index" @click="getStaffStatus(item)">
            <div class="staff-icon iconfont icon-renyuan" style="color: #d63031;" v-if="item.online==0"></div>
            <div class="staff-icon iconfont icon-renyuan" style="color: #00b894;" v-else></div>
            <div class="staff-name">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="staff-status">
      外勤人员在线状态：在线{{ online }}人，离线{{ offline }}人
    </div>
    <!-- <div class="input-card">
      <h4>轨迹回放控制</h4>
      <div class="input-item">
        <input type="button" class="btn" value="开始动画" id="start" @click="startAnimation()"/>
        <input type="button" class="btn" value="暂停动画" id="pause" @click="pauseAnimation()"/>
      </div>
      <div class="input-item">
        <input type="button" class="btn" value="继续动画" id="resume" @click="resumeAnimation()"/>
        <input type="button" class="btn" value="停止动画" id="stop" @click="stopAnimation()"/>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      map: null,
      marker: null,
      markers: null,
      allMarkers: [],
      lineArr: [
          [108.904848, 34.231743],
          [108.909561, 34.239321],
          [108.915196, 34.236616],
          [108.918353, 34.235232],
          [108.919434, 34.234558],
          [108.922024, 34.233272],
          [108.922223, 34.232873],
          [108.917385, 34.225359],
          [108.911591, 34.216328],
          [108.901463, 34.204965],
          [108.889726, 34.205025],
          [108.889683, 34.211502],
          [108.889855, 34.236908],
          [108.890026, 34.247622],
          [108.899124, 34.244075],
          [108.895176, 34.234993],
          [108.897343, 34.234283],
          [108.899661, 34.233254],
          [108.903716, 34.231484],
      ],
      polyline: [],
      passedPolyline: null,
      treeData: [],
      tableData: [],
      department: [],
      defaultProps: {
        children: "childTree",
        label: "name",
      },
      roleId: 0,
      online: 0,
      offline: 0,
      isCompany: false,
      isDepartment: false,
      staffList: [],
      staffAllList: []
    };
  },
  methods: {
    // 部门菜单折叠切换
    companyToggle() {
      this.isCompany = !this.isCompany
    },

    // 部门菜单折叠切换
    departmentToggle(arr, item) {
      arr.forEach(v=>{
        if(v.id==item.id){
          v.isDepartment = !v.isDepartment
        }
      })
    },

    // 点击全部人员
    staffAllPosition(arr) {
      let allStaff = []
      arr.forEach(v=>{
        allStaff.push(v.id)
      })
      this.$ajax.post('queryStaffPosition', {
        staffIds: allStaff,
      }).then(res => {
        console.log(res.data)
        this.staffAllList = res.data
        this.initAllMarker()
      })
    },

    // 点击单个人员
    staffPosition(item){
      let staff = []
      staff.push(item.id)
      this.$ajax.post('queryStaffPosition', {
        staffIds: staff,
      }).then(res => {
        console.log(res.data)
        this.staffList = res.data
        this.initOneMarker()
      })
    },

    // 初始化地图
    initMap() {
      this.map = new AMap.Map("container", {
        resizeEnable: true,
        zooms: [3,20],
        expandZoomRange: true,
        zoom: 16, //地图显示的缩放级别
      });

      // this.initFirstMarker()
      this.getCurrentLocation()
    },

    // 初始所有人marker
    initAllMarker() {
      // 移除已创建的 marker
      this.map.remove(this.allMarkers)
      // 创建marker
      if(this.staffAllList.length>0){
        this.staffAllList.forEach(v => {
          var markers = new AMap.Marker({
            map: this.map,
            position: [v.longitude, v.latitude],
            icon: require("../../img/online.png"),
            offset: new AMap.Pixel(-15, -30),
            autoRotation: true
          });
          markers.setLabel({
            offset: new AMap.Pixel(0, 5),
            content: `<div class='online-info'>${v.staffName}</div>`, 
            direction: 'bottom'
          })
          this.allMarkers.push(markers)
        })
        this.map.setFitView();
      }
    },

    // 初始化单个marker
    initOneMarker() {
      // 移除已创建的 marker
      this.map.remove(this.allMarkers);
      if(this.markers){
        this.markers.setMap(null);
        this.markers = null;
      }
      // 创建marker
      if(this.staffList.length>0){
        this.staffList.forEach(v => {
          this.markers = new AMap.Marker({
            map: this.map,
            position: [v.longitude, v.latitude],
            icon: require("../../img/online.png"),
            offset: new AMap.Pixel(-15, -30),
            autoRotation: true
          });
          this.markers.setLabel({
            offset: new AMap.Pixel(0, 5),
            content: `<div class='online-info'>${v.staffName}</div>`, 
            direction: 'bottom'
          })
        })
        this.map.setFitView();
      }
    },

    // 初始化第一个marker并绘制轨迹
    initFirstMarker() {
      // 初始化第一个marker
      this.marker = new AMap.Marker({
        map: this.map,
        position: [108.904848,34.231743],
        icon: "https://webapi.amap.com/images/car.png",
        offset: new AMap.Pixel(-26, -13),
        autoRotation: true,
        angle: -90,
      });

      // 绘制轨迹
      let polyline = new AMap.Polyline({
        map: this.map,
        path: this.lineArr,
        showDir: true,
        strokeColor: "#28F", //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        // strokeStyle: "solid"  //线样式
      });

      let passedPolyline = new AMap.Polyline({
        map: this.map,
        // path: this.lineArr,
        strokeColor: "#AF5", //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        // strokeStyle: "solid"  //线样式
      });

      this.marker.on("moving", function (e) {
        passedPolyline.setPath(e.passedPath);
      });

      this.map.setFitView();
    },

    // 获取当前定位
    getCurrentLocation() {
      // 点击获取当前位置
      var geolocation = new AMap.Geolocation({
        // 是否使用高精度定位，默认：true
        enableHighAccuracy: true,
        // 设置定位超时时间，默认：无穷大
        timeout: 10000,
        // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
        buttonOffset: new AMap.Pixel(10, 20),
        //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        zoomToAccuracy: true,     
        //  定位按钮的排放位置,  RB表示右下
        buttonPosition: 'RB'
      })

      this.map.addControl(geolocation)
      geolocation.getCurrentPosition()
    },

    startAnimation() {
      this.marker.moveAlong(this.lineArr, 2000);
    },

    pauseAnimation() {
      this.marker.pauseMove();
    },

    resumeAnimation() {
      this.marker.resumeMove();
    },

    stopAnimation() {
      this.marker.stopMove();
    },

    getStaffStatus(item) {
      console.log(item.name + '--->' + item.online)
    },

    loadList() {
      this.$ajax.get('queryDepartmentStaff').then(res => {
        var online = []
        var offline = []
        res.data.forEach(v=>{
          v.childTree.forEach(item=>{
            item.isDepartment = false
          })
          // 统计公司在线离线人员
          v.staffs.forEach(staff=>{
            if(staff.online==1){
              online.push(staff.online)
            }else{
              offline.push(staff.online)
            }
          })
        })
        this.online = online.length
        this.offline = offline.length

        this.treeData = res.data
      })
    },
  },
  mounted() {
    this.initMap();
    this.loadList();
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  }
};
</script>

<style scope>
/* @import "https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css"; */
.wrapper {
  position: relative;
}

#container {
  width: 100% - 5%;
  height: 825px;
  cursor: pointer;
  position: relative;
}

.input-card {
  padding: 0 20px 10px;
  position: fixed;
  bottom: 40px;
  right: 80px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(180, 180, 180);
  color: #999;
  font-size: 15px;
}

.input-card .btn {
  margin-right: 1.2rem;
  margin-bottom: 0.7rem;
  width: 6rem;
  border: 1px solid rgb(37, 150, 255);
  border-radius: 10px;
  background: #FFF;
  color: rgb(37, 150, 255);
  cursor: pointer;
}

.input-card .btn:last-child {
  margin-right: 0;
}

.staff-list {
  width: 240px;
  height: 825px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: rgb(0,0,0,0.7);
}

.list-content {
  height: 825px;
  overflow: auto;
  color: #FFF;
}

.list-tree {
  width: 100%;
  color: #FFF;
}

.list-tree .tree-title {
  background: none;
  font-size: 16px;
}

.company {
  overflow: auto;
}

.arrow {
  display: inline-block;
  transform:rotate(180deg);
  transition: all .3s ease;
  margin-right: 10px;
}

.unarrow {
  display: inline-block;
  transform:rotate(90deg);
}

.company-name {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  transition: all .3s ease;
  cursor: pointer;
}

.company-name:hover{
  background-color: #66b1ff87;
}

.company-all-staff {
  height: 30px;
  line-height: 30px;
  padding-left: 65px;
  transition: all .3s ease;
  cursor: pointer;
}

.company-all-staff:hover{
  background-color: #66b1ff87;
}

.company-staff {
  height: 30px;
  line-height: 30px;
  padding-left: 65px;
  transition: all .3s ease;
  cursor: pointer;
}

.company-staff:hover{
  background-color: #66b1ff87;
}

.department-name {
  height: 40px;
  line-height: 40px;
  padding-left: 40px;
  transition: all .3s ease;
  cursor: pointer;
}

.department-name:hover{
  background-color: #66b1ff87;
}

.department-all-staff {
  height: 30px;
  line-height: 30px;
  padding-left: 85px;
  transition: all .3s ease;
  cursor: pointer;
}

.department-all-staff:hover{
  background-color: #66b1ff87;
}

.department-staff {
  height: 30px;
  line-height: 30px;
  padding-left: 85px;
  transition: all .3s ease;
  cursor: pointer;
}

.department-staff:hover{
  background-color: #66b1ff87;
}


.list-tree .el-tree {
  background: none;
  color: #FFF;
}

.custom-tree-node {
  font-size: 14px;
}

.status-list {
  width: 100%;
}

.status-item {
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
  cursor: pointer;
}

.status-item:hover {
  background-color: #66b1ff87;
}

.status-item div {
  display: inline-block;
}

.staff-icon {
  width: 30px;
}

.staff-name {
  width: 100px;
  text-align: left;
}

.staff-status {
  width: 60%;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 14%;
  background: rgb(238,247,229, 0.8);
  color: #51C41B;
  border: 1px solid #82c464;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  font-size: 14px;
}

.wrapper .amap-marker-label{
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  color: #FFF;
  background-color: #20bf6ac5;
  padding: 10px;
  box-shadow: 0px 0px 10px rgb(167, 167, 167);
}

.online-info{
  position: relative;
  top: 0;
  right: 0;
  min-width: 0;
}
</style>

<style lang="less">
.el-tree-node__content {
  height: 40px;
}
.el-dropdown-menu__item {
  padding: 0;
  > div {
    padding: 0 20px;
  }
}
.list-tree .el-tree-node__content:hover {   
  background-color: #66b1ff87;
}
.list-tree .el-tree-node:focus > .el-tree-node__content {
  background-color: #66b1ff87 !important;
}
</style>